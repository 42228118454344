import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import WebriQForm from "@webriq/gatsby-webriq-form"

import "react-lazy-load-image-component/src/effects/blur.css"

// import mouseWheel from "../images/mouse-wheel.gif"
import whiteCurve from "../images/rounded-img.svg"

import phoneIcon from "../images/about-us/phone.svg"
import emailIcon from "../images/about-us/mail.svg"
import locationIcon from "../images/about-us/address.svg"

const AboutUs = () => (
  <Layout>
    <SEO title="Contact Us" description="Contact Us Page" />
    <div className="contact-container">
      <div className="hero-contact-container">
        <div className="container">
          <div className="text-center header-box">
            <h2 className="text-white">We'd love to hear from you</h2>
            <p className="pb-3 pt-3 text-white">
              Fill out the form below, or give us a call!
            </p>
          </div>
          <img src={whiteCurve} alt="White curve shape" />
        </div>
      </div>
      {/* <div className="container-fluid hero-section">
      <div className="row">
        <div className="col-md-5 vertical-align d-none-mobile">
          <div className="brandstamp">
            <h1>We'd love to hear from you</h1>
            <p className="pb-3 pt-3">
              Fill out the form below, or give us a call!
              <br />
            </p>
          </div>
        </div>
        <div className="col-md-7 contact-hero-img vertical-align">
          <div className="brandstamp-mobile text-white">
            <h1>We'd love to hear from you</h1>
            <p className="mb-5">
              Fill out the form below, or give us a call!
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-12 text-center scroll-down">
      <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
    </div> */}

      <div className="container contact-wrapper">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div
              className="card apply-container mt-5 p-0"
              style={{ border: "none" }}
            >
              <div className="card-body">
                <h2 className="card-title mt-0">
                  How can we help? Because that's what we're here for!
                </h2>
                <WebriQForm
                  method="POST"
                  data-form-id="8b0ecf6a-3810-47b9-a1bb-a4176efd251d"
                  name="Contact Us form"
                  className="contact-us form"
                  data-thankyou-url="/thank-you"
                >
                  <div className="form-row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <input
                          style={{ paddingTop: "0.5rem" }}
                          className="form-control"
                          type="text"
                          name="Full Name"
                          id="full_name"
                          placeholder="Enter your full name"
                        />
                        <input
                          className="form-control"
                          style={{ paddingTop: "0.5rem" }}
                          type="email"
                          name="Email Address"
                          id="email_address"
                          placeholder="Enter your personal email"
                        />
                        <input
                          className="form-control"
                          style={{ paddingTop: "0.5rem" }}
                          type="tel"
                          name="Phone Number"
                          id="phone_number"
                          placeholder="Enter your phone number"
                        />
                        <textarea
                          className="form-control"
                          style={{ paddingTop: "0.5rem", height: "auto" }}
                          type="text"
                          name="Message"
                          id="message"
                          placeholder="How can we help?"
                          cols="3"
                          rows="3"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <div className="webriq-recaptcha" />
                      </div>
                    </div>

                    <div className="col-12 text-center">
                      <div className="form-group">
                        <button
                          className="btn-global hvr-shadow mt-3"
                          type="submit"
                        >
                          Let's connect!
                        </button>
                      </div>
                    </div>
                  </div>
                </WebriQForm>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-info-box d-flex align-items-center mb-3">
              <img
                src={phoneIcon}
                alt="Contact Details"
                className="contact-info-img mr-3"
                style={{ width: "7%" }}
              />
              <div>
                <p className="mb-0">Call or Text</p>
                <a href="tel:303-587-2389">303.587.2389</a>
              </div>
            </div>
            <div className="contact-info-box d-flex align-items-center mb-3">
              <img
                src={emailIcon}
                alt="Email Address"
                className="contact-info-img mr-3"
                style={{ width: "7%" }}
              />
              <a href="mailto:Darlene@missionmortgageco.com">
                Darlene@missionmortgageco.com
              </a>
            </div>
            <div className="contact-info-box d-flex align-items-center">
              <img
                src={locationIcon}
                alt="Location"
                className="contact-info-img mr-3"
                style={{ width: "7%" }}
              />
              <div>
                <p className="mb-0">8181 Arista Place, Suite 100</p>
                <p className="mb-0">Broomfield, CO 80021</p>
              </div>
            </div>
            <div
              className="card apply-container mt-5 text-center"
              style={{ border: "none" }}
            >
              <div className="card-body">
                <h5 className="card-title mt-0">
                  Want to become pre-approved?
                </h5>
                <p className="card-text">Apply online with the link below!</p>
                <a
                  href="https://plus.preapp1003.com/Darlene-Franklin"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-global mr-3 text-white"
                  type="button"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="apply-section">
        <div className="protruded-gradient"></div>
        <div className="container">
          <div className="apply-container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div>
                  <h2 className="mb-2 mt-3">
                    Let's get started
                    <br />
                  </h2>
                  <p className="pb-4">
                    Getting pre-approved can be quick and easy. Start the
                    process today!
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col">
                    <a
                      href="https://plus.preapp1003.com/Darlene-Franklin"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-global w-100 mb-3"
                    >
                      Apply Now
                    </a>
                  </div>
                  <div className="col">
                    <Link
                      to="/loan-process"
                      className="btn-global-inverted w-100 text-center"
                      style={{ padding: "10px 20px" }}
                    >
                      Our Process
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutUs
